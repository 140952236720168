.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

@media screen and (min-width: 992px) {
  .carousel {
    width: 50%;
  }
}
